import React, { useEffect, useState } from 'react'
import axios from 'axios';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withRouter } from "../components/WithRouter";
import { apiUrl } from '../helpers';
import Spinner from '../components/Spinner';
import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, Button } from '../components';

const PageContainerWrapper = styled(PageContainer)`
.old-step {
  text-decoration: line-through;
  color: red;
}

/* Style for new step number */
.new-step {
  font-weight: bold;
  color: green;
}
`;

const ContentfulEntries = () => {
    const [loading, setLoading] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        getEntries();
    }, []);

    const getEntries = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(apiUrl('/api/contentful/entries'));
            setEntries(payload);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(entries);

        // Reorder the item based on drag-and-drop action
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update newStepNumber and check if stepNumber has changed
        const updatedItems = items.map((item, index) => {
            const newStepNumber = index + 1;  // Update newStepNumber based on position
            const isUpdated = item.stepNumber !== newStepNumber;  // Check if old and new step numbers differ

            return {
                ...item,
                oldStepNumber: item.stepNumber,  // Keep track of old step number
                newStepNumber,  // Update new step number
                updated: isUpdated,  // Set updated to true if step numbers don't match
            };
        });

        console.log("🚀 ~ updatedItems ~ updatedItems:", updatedItems);
        setEntries(updatedItems);  // Update state with reordered and flagged entries
    };

    const updateStepSaveStepNumber = async () => {
        try {
            setSaveInProgress(true);
            const updated_entries = entries.filter(entry => entry.updated);

            const { data } = await axios.post('/api/contentful/entries/update', { entries: updated_entries });
            console.info("🚀 ~ updateStepSaveStepNumber ~ data:", data);
            getEntries();
            setSaveInProgress(false);
        } catch (error) {
            console.error("🚀 ~ updateStepSaveStepNumber ~ error:", error);
            setSaveInProgress(false);
        }
    };

    return (
        <PageContainerWrapper>
            {
                loading &&
                <Spinner />
            }
            <TableContainer maxHeight='88vh'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppableTable">
                        {(provided) => (
                            <Table
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="table  table-bordered"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderElement className='w-25' scope="col">
                                            Associated Variable
                                        </TableHeaderElement>
                                        <TableHeaderElement scope="col">
                                            Step Number
                                        </TableHeaderElement>
                                        <TableHeaderElement scope="col">
                                            Old Step Number
                                        </TableHeaderElement>
                                        <TableHeaderElement className='w-25' scope="col">
                                            Updated At
                                        </TableHeaderElement>
                                        <TableHeaderElement className='w-10' scope="col">
                                            Created At
                                        </TableHeaderElement>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        entries.map((dt, index) => (
                                            <Draggable key={dt.id} draggableId={dt.id} index={index}>
                                                {(provided) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <TableDataElement className='w-25'>
                                                            <a href={dt.url} target="_blank" rel="noopener noreferrer" title={dt.url}>
                                                                {dt.label}
                                                            </a>
                                                        </TableDataElement>
                                                        <TableDataElement>
                                                            {dt.newStepNumber}
                                                        </TableDataElement>
                                                        <TableDataElement className={dt.updated ? 'old-step' : ''}>
                                                            {dt.oldStepNumber}
                                                        </TableDataElement>
                                                        <TableDataElement className='w-25'>
                                                            {dt.updatedAt}
                                                        </TableDataElement>
                                                        <TableDataElement className='w-25'>
                                                            {dt.createdAt}
                                                        </TableDataElement>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </TableBody>
                            </Table>
                        )}
                    </Droppable>
                </DragDropContext>
            </TableContainer>
            <Button type='button' disabled={saveInProgress} onClick={updateStepSaveStepNumber}>
                {saveInProgress ? 'Update In Progress...' : 'Update'}
            </Button>
        </PageContainerWrapper>
    )
};

export default withRouter(ContentfulEntries);