import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllEquipments, getAllRoles, updateRoles } from '../../actions/masterAction';
import ReactPaginate from 'react-paginate';
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput, StyledTextarea, Icon, Checkbox, ButtonContainer, SearchButton, NoDataFound, PageCountContainer
} from '../';
import Modal from 'react-bootstrap/Modal';
import MultiTextInput from '../MultiTextInput';
import Select from 'react-select';
import { DropDownStyle, pageSizeOptions } from '../../helpers';
import { checkDataIsValid } from '../../CheckData';
class Roles extends Component {
    state = {
        roles: [],
        pageNumber: 0,
        pageSize: 10,
        pageCount: 0,
        totalCount: 0,
        sortBy: 'name',
        order: 'asc',
        roleForEdit: {},
        edit: false,
        isModalOpen: false,
        equipments: [],
        name: ''
    };
    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(getAllRoles({ ...this.state }));
        dispatch(getAllEquipments({}));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { allRoles, roleCount, equipments } = updatedProps;
        const tempEquipments = equipments && equipments.length && equipments.map((eq) => ({ value: eq.id, label: eq.name }));
        const pageCount = Math.ceil(roleCount / pageSize);
        this.setState({
            roles: allRoles,
            totalCount: roleCount,
            pageCount: pageCount,
            equipments: tempEquipments
        });
    };

    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getAllRoles({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };
    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 10,
            name: value
        });
    };
    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getAllRoles({ ...this.state }));
    };
    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 10,
            edit: false,
            roleForEdit: {},
            sortBy: 'name',
            order: 'asc',
            name: ''
        });
        dispatch(getAllRoles({ pageNumber: 0, pageSize: 10, }));
    };
    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getAllRoles({ ...this.state, pageNumber: 1, pageSize: value }));
    };
    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 10
        });
        await dispatch(getAllRoles({ ...this.state, pageNumber: 1, pageSize: 10, sortBy, order }));
    };
    handelEditRow = (role) => {
        this.setState({
            edit: true,
            roleForEdit: role
        });
    };
    handelModelClose = () => {
        const { roleForEdit } = this.state;
        this.setState({
            isModalOpen: false,
            roleForEdit: { ...roleForEdit, changeForAll: false }
        });
    };
    handelCheckboxChange = ({ target: { checked, name } }) => {
        const { roleForEdit } = this.state;
        this.setState({
            roleForEdit: { ...roleForEdit, changeForAll: checked }
        });
    };
    handelDropDownChange = (name, value) => {
        const newDefaultEquipment = value.length ? value.map((defaultEquipment) => defaultEquipment.value) : [];
        const { roleForEdit } = this.state;
        this.setState({
            roleForEdit: { ...roleForEdit, defaultEquipment: newDefaultEquipment }
        });
    };
    handelInputChange = (value, name) => {
        const { roleForEdit } = this.state;
        this.setState({
            roleForEdit: { ...roleForEdit, [name]: value.trimStart() }
        });
    };
    updateRoles = async () => {
        const { dispatch } = this.props;
        const { roleForEdit } = this.state;
        const isValid = checkDataIsValid({ 'Role Name': roleForEdit.name });
        if (isValid) {
            const data = await dispatch(updateRoles(roleForEdit));
            if (data) {
                await dispatch(getAllRoles(this.state));
                this.setState({
                    edit: false,
                    isModalOpen: false
                });
            };
        };
    };
    handleTaskChange = (e) => {
        const newTasks = e.length ? e.map((task) => task.value) : [];
        const { roleForEdit } = this.state;
        this.setState({
            roleForEdit: { ...roleForEdit, jobTasks: newTasks }
        });
    };
    render() {
        const { roles, pageSize, pageCount, pageNumber, sortBy, order, roleForEdit, edit, isModalOpen, equipments, name, totalCount } = this.state;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        return (
            <PageContainer width='100%'>
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Roles</StyledLabel>
                            <StyledInput
                                type="text"
                                value={name}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <SearchButton disabled={!name.length} type='submit' onClick={this.getFilteredData} width='20%' >
                                    <Icon className='fa fa-search'></Icon>
                                </SearchButton>
                                <SearchButton reset={true} onClick={this.resetFilter} width='20%' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </SearchButton>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer maxHeight='73vh'>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-20' scope="col">Roles
                                    {sortBy !== 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'name' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('name', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-30' scope="col">Description
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20' scope="col">Job Tasks
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20' scope="col">Default Equipments
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10 text-center' scope="col">Action </TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roles && roles.map((role) =>
                                <TableRow key={role._id}>
                                    {edit && roleForEdit._id === role._id ?
                                        <TableDataElement className='w-20'>
                                            <StyledInput name='name' onChange={(e) => this.handelInputChange(e.target.value, 'name')} value={roleForEdit.name ? roleForEdit.name : ''} />
                                        </TableDataElement> :
                                        <TableDataElement className='w-20'>{
                                            role.name ? role.name : '--'}
                                        </TableDataElement>
                                    }
                                    {edit && roleForEdit._id === role._id ?
                                        <TableDataElement className='w-30'>
                                            <StyledTextarea name='description' onChange={(e) => this.handelInputChange(e.target.value, 'description')} value={roleForEdit.description ? roleForEdit.description : ''} />
                                        </TableDataElement> :
                                        <TableDataElement className='w-30'>{
                                            role.description ? role.description : '--'}
                                        </TableDataElement>
                                    }   {
                                        edit && roleForEdit._id === role._id ?
                                            <TableDataElement className='w-20'>
                                                <MultiTextInput
                                                    data={role.jobTasks.length && role.jobTasks.map((task) => {
                                                        return { label: task, value: task };
                                                    })}
                                                    onChange={this.handleTaskChange}
                                                    placeholder='Type a Job Task and press enter...'
                                                />
                                            </TableDataElement>
                                            :
                                            <TableDataElement className='w-20'>
                                                {role.jobTasks && role.jobTasks.length ? role.jobTasks.join(", ").toString() : '--'}
                                            </TableDataElement>}
                                    {
                                        edit && roleForEdit._id === role._id ?
                                            <TableDataElement className='w-20'>
                                                <Select
                                                    styles={DropDownStyle}
                                                    options={equipments}
                                                    defaultValue={equipments.filter((eq) => roleForEdit.defaultEquipment.includes(eq.value))}
                                                    onChange={(e) => this.handelDropDownChange('defaultEquipment', e)}
                                                    isMulti={true}
                                                />
                                            </TableDataElement> :
                                            <TableDataElement className='w-20'>
                                                {role.defaultEquipment && role.defaultEquipment.length ? role.defaultEquipment.join(", ").toString() : '--'
                                                }
                                            </TableDataElement>
                                    }
                                    <TableDataElement className='w-10 text-center'>{edit && roleForEdit._id === role._id ?
                                        <>

                                            <Icon
                                                onClick={() => this.setState({
                                                    isModalOpen: true
                                                })}
                                                className='fa fa-save text-success' />
                                            <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: false })} />
                                        </> :
                                        <Icon onClick={() => this.handelEditRow(role)} className='fa fa-pencil text-success' />}
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!roles || !roles.length) &&
                        <NoDataFound>No Data Found</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                <Modal
                    show={isModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Apply for all existing and future practices <Checkbox name='changeForAll' onChange={this.handelCheckboxChange} checked={roleForEdit.changeForAll} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.updateRoles} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        )
    }
};

const mapStateToProps = (state) => ({
    allRoles: state.master.roles?.data,
    roleCount: state.master.roles?.count,
    equipments: state.master.equipments?.data,
});

export default connect(mapStateToProps)(Roles);