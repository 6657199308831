import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import PracticeIcon from '../assets/images/practice-gray-icon.svg';
import LocationIcon from '../assets/images/glyph-location-gray.svg';
import LocationIconSelected from '../assets/images/glyph-location-selected.svg';
import UserIcon from '../assets/images/glyph-user-gray.svg';
import UserIconSelected from '../assets/images/glyph-user-selected.svg';
import PracticeIconSelected from '../assets/images/practice-icon-selected.svg';
import AdministrationIcon from '../assets/images/sidebar-administration-normal.svg';
import AdminIconSelected from '../assets/images/sidebar-administration-selected.svg';
import logo from '../assets/images/ocoord-logo.svg';
import RecordsIcon from '../assets/images/sidebar-records-normal.svg';
import RecordsIconSelected from '../assets/images/sidebar-records-selected.svg';
import signOut from '../assets/images/signOut.svg';
import check from '../assets/images/glyph-checkmark-green.svg';
import calloutArrow from '../assets/images/account-callout-arrow.png';
import SelectedSDSIcon from '../assets/images/sds-green.svg';
import NormalSDSIcon from '../assets/images/sds-gray.svg';
import LibraryIconSelected from '../assets/images/sidebar-library-selected.svg';
import LibraryIcon from '../assets/images/sidebar-library-normal.svg';
import Contentful from '../assets/images/contentful.svg';
import { StyledLink } from './CommonComponents';

const NavBarStyled = styled.nav`
  position: fixed;
  width: 60px;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #343747;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  user-select: none;
  animation: navigation-sidebar-slide-in 0.5s cubic-bezier(0.105, 0.64, 0.345, 1.005);
  animation-delay: 0.5s;
`;
const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NavigationIcon = styled.div`
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 10px 0px;
  position: relative;
  &:hover div {
    display: inherit !important;
  }
  .selected {
    img {
      content: ${(props) => `url(${props.selectedIcon})`};
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    img {
      vertical-align: middle;
      width:${(props) => (props.width ? `${props.width}px` : '30px')}; 30px;
      height: ${(props) => (props.height ? `${props.height}px` : '30px')};;
    }
  }
`;
const NavLinkToast = styled.div`
  background: #9598a3;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  // box-shadow: rgba(0, 0, 0, 0.082) 0px 10px 20px 4px;
  position: absolute;
  left: 65px;
  top: 65%;
  line-height: 10px;
  transform: translateY(-50%);
  display: none;
`;
const OcoordLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 10px;
`;
const SupportContainer = styled.div`
  background-color: #1d202b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;
const NameIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a7dcb9;
  font-size: 11px;
  font-weight: 800;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background 0.15s ease-in-out;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  border-radius: 100%;
  margin: 10px;
`;
const UserPanel = styled.div`
  margin-bottom:60px;
  position: absolute;
  z-index: 100;
  min-width: 250px;
  left: 70px;
  background: white;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  a {
    line-height: 25px;
  }
`;
const UserPanelImage = styled.div`
  position: absolute;
  width: 11px;
  margin-top: 18px;
  height: 25px;
  left: -10px;
  background-image: url(${calloutArrow});
  background-repeat: no-repeat;
`;
const UserPanelSection = styled.div`
  padding: 10px 20px;
  color: black;
  .practice {
    color: black;
  }
  .location {
    text-indent: 30px;
    cursor: pointer;
  }
  .unSelected {
    color: black;
  }
  .selected {
    /* background-image: url("${check}"); */
    background-position: left left;
    background-repeat: no-repeat;
    font-weight:500;
    background-size: 60% 60%;
    color: var(--key-color);
  }

`;
const UserPanelName = styled.div`
  color: black;
  font-weight: 600;
  word-break: break-all;
  font-size:14px;
`;
const UserPanelEmail = styled.div`
  color: black;
  font-size:14px;
`;
const UserPanelContent = styled.div``;
class NavBar extends React.Component {
  state = {
    showPanel: false
  };

  render() {
    const { authenticated, user: { user } } = this.props;
    const { showPanel } = this.state;
    if (!authenticated || !Cookies.get('adminToken')) {
      return null;
    } else {
      return (
        <NavBarStyled>
          <Link to='/users'>
            <OcoordLogo src={logo} />
          </Link>
          <IconsContainer>
            <NavigationIcon selectedIcon={UserIconSelected}>
              <NavLink
                onClick={() => { this.setState({ showPanel: false }) }}
                to='/users' className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={UserIcon} alt='Users' />
              </NavLink>
              <NavLinkToast>Users</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon width='33' height='33' selectedIcon={PracticeIconSelected}>
              <NavLink
                onClick={() => { this.setState({ showPanel: false }) }}
                to='/practice' className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={PracticeIcon} alt='Practices' />
              </NavLink>
              <NavLinkToast>Practices</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={LocationIconSelected}>
              <NavLink
                onClick={() => { this.setState({ showPanel: false }) }}
                to='/location'
                className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={LocationIcon} alt='Locations' />
              </NavLink>
              <NavLinkToast>Locations</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={RecordsIconSelected}>
              <NavLink onClick={() => { this.setState({ showPanel: false }) }} to='/record' className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={RecordsIcon} alt='Library' />
              </NavLink>
              <NavLinkToast>Records</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={LibraryIconSelected}>
              <NavLink onClick={() => { this.setState({ showPanel: false }) }} to='/library' className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={LibraryIcon} alt='Library' />
              </NavLink>
              <NavLinkToast>Library</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={Contentful}>
              <NavLink onClick={() => { this.setState({ showPanel: false }) }} to='/contentful' className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={Contentful} alt='Library' />
              </NavLink>
              <NavLinkToast>Library</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={SelectedSDSIcon}>
              <NavLink
                to='/sds'
                className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={NormalSDSIcon} alt='Safety Data Sheet' />
              </NavLink>
              <NavLinkToast>SDS</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={AdminIconSelected}>
              <NavLink
                onClick={() => { this.setState({ showPanel: false }) }}
                to='/master'
                className={({ isActive }) => (isActive ? 'selected' : 'nav-link')}>
                <img src={AdministrationIcon} alt='Administration' />
              </NavLink>
              <NavLinkToast>Masters</NavLinkToast>
            </NavigationIcon>
          </IconsContainer>
          <SupportContainer>
            <NameIcon onClick={() => { this.setState({ showPanel: !showPanel }) }}>
              {user.firstName[0]}
              {user.lastName[0]}
            </NameIcon>
            <NavigationIcon >
              <NavLink
                onClick={() => { this.setState({ showPanel: false }) }}
                to='/login'>
                <img src={signOut} alt='Logout' />
              </NavLink>
              <NavLinkToast>Logout</NavLinkToast>
            </NavigationIcon>
            {showPanel &&
              <UserPanel>
                <UserPanelImage />
                <UserPanelContent>
                  <UserPanelSection>
                    <UserPanelName>{user.fullName}</UserPanelName>
                    <UserPanelEmail>{user.email}</UserPanelEmail>
                    <StyledLink onClick={() => { this.setState({ showPanel: false }) }} to='/account'>My Account</StyledLink>
                  </UserPanelSection>
                </UserPanelContent>
              </UserPanel>
            }
          </SupportContainer>
        </NavBarStyled>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.user.authenticated,
    user: state.user.currentUser
  };
};

export default connect(mapStateToProps)(NavBar);
